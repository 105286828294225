import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { Trans } from "gatsby-plugin-react-i18next"
import ContactForm from "../components/ContactForm"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Contact() {
  const { t } = useTranslation()
  return (
    <Layout subTitle={t("Contact")}>
      <section className="section has-text-centered">
        <h2 className="title is-2 is-spaced">
          <Trans>Contact Us</Trans>
        </h2>
        <h3 className="subtitle is-4 is-spaced">
          <Trans>
            Thanks for reaching out! Please fill in the form below and we'll get
            back to you as soon as possible!
          </Trans>
        </h3>
      </section>
      <section className="section">
        <ContactForm />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
