import React, { useState } from "react"
import { Formik } from "formik"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import emailjs from "@emailjs/browser"

export default function ContactForm() {
  const { t } = useTranslation()
  const [formState, setFormState] = useState({
    isSubmitted: false,
    isError: false,
    errorMessage: "",
  })

  function showDialog() {
    if (formState.isError) {
      return (
        <div className="notification is-danger is-light">
          <strong>
            <Trans>Sorry, something went wrong!</Trans>
          </strong>
          <p>
            <Trans>
              We have encountered an issue sending your message. Please contact
              us instead via our{" "}
            </Trans>
            <a href="https://www.facebook.com/Freundeskreis-N%C3%BCrnberg-Glasgow-eV-1483994971881505/">
              <Trans>Facebook page</Trans>
            </a>
          </p>
          {formState.errorMessage ? (
            <p>
              <Trans>Error: </Trans>
              {formState.errorMessage}
            </p>
          ) : null}
        </div>
      )
    } else {
      return (
        <div className="notification is-success is-light">
          <strong>
            <Trans>Thank you for getting in touch!</Trans>
          </strong>
          <p>
            <Trans>
              We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon! Have a great day!
            </Trans>
          </p>
        </div>
      )
    }
  }
  return formState.isSubmitted ? (
    showDialog()
  ) : (
    <Formik
      initialValues={{
        email: "",
        name: "",
        message: "",
        captcha: "",
        privacyAgreement: false,
      }}
      validate={values => {
        const errors = {}
        if (!values.email) {
          errors.email = t("Required")
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = t("Invalid email address")
        }
        if (!values.name) {
          errors.name = t("Required")
        }
        if (!values.message) {
          errors.message = t("Required")
        }
        if (!values.captcha) {
          errors.captcha = t("Required")
        } else if (values.captcha.toLowerCase() !== "edinburgh") {
          errors.captcha = t("Try again")
        }
        if (!values.privacyAgreement) {
          errors.privacyAgreement = t(
            t("You must accept the terms & conditions to send us a message")
          )
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        emailjs
          .send(
            "service_z86jf1c",
            "template_n2ghvss",
            values,
            "-phnY4QOuFX3lyLFT"
          )
          .then(
            result => {
              console.log(JSON.stringify(result))
              resetForm()
              setSubmitting(false)
              setFormState({
                isSubmitted: true,
                isError: false,
                errorMessage: "",
              })
            },
            error => {
              console.log(JSON.stringify(error))
              setSubmitting(false)
              setFormState({
                isSubmitted: true,
                isError: true,
                errorMessage: error.text,
              })
            }
          )
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">
              <Trans>Name</Trans>
              <div className="control has-icons-left">
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder={t("Name")}
                  className={[
                    "input",
                    errors.name && touched.name && errors.name
                      ? "is-danger"
                      : null,
                    !errors.name && touched.name && !errors.name
                      ? "is-success"
                      : null,
                  ].join(" ")}
                />
                <span className="icon is-small is-left">
                  <i className="bi bi-person-fill"></i>
                </span>
              </div>
            </label>
            <p className="help is-danger">
              {errors.name && touched.name && errors.name}
            </p>
          </div>
          <div className="field">
            <label className="label">
              <Trans>Email</Trans>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={t("Email")}
                  className={[
                    "input",
                    errors.email && touched.email && errors.email
                      ? "is-danger"
                      : null,
                    !errors.email && touched.email && !errors.email
                      ? "is-success"
                      : null,
                  ].join(" ")}
                />
                <span className="icon is-small is-left">
                  <i className="bi bi-envelope"></i>
                </span>
              </div>
            </label>
            <p className="help is-danger">
              {errors.email && touched.email && errors.email}
            </p>
          </div>
          <div className="field">
            <label className="label">
              <Trans>Message</Trans>
              <div className="control has-icons-left">
                <textarea
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  placeholder={t("Message")}
                  className={[
                    "input",
                    "textarea",
                    errors.message && touched.message && errors.message
                      ? "is-danger"
                      : null,
                    !errors.message && touched.message && !errors.message
                      ? "is-success"
                      : null,
                  ].join(" ")}
                ></textarea>
                <span className="icon is-small is-left">
                  <i className="bi bi-chat"></i>
                </span>
              </div>
            </label>
            <p className="help is-danger">
              {errors.message && touched.message && errors.message}
            </p>
          </div>
          <div className="field">
            <label className="label">
              <Trans>
                What is the capital of Scotland? (Hint: Not Glasgow!)
              </Trans>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  name="captcha"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.captcha}
                  placeholder={t("Are you a robot?")}
                  className={[
                    "input",
                    errors.captcha && touched.captcha && errors.captcha
                      ? "is-danger"
                      : null,
                    !errors.captcha && touched.captcha && !errors.captcha
                      ? "is-success"
                      : null,
                  ].join(" ")}
                />
                <span className="icon is-small is-left">
                  <i className="bi bi-robot"></i>
                </span>
              </div>
            </label>
            <p className="help is-danger">
              {errors.captcha && touched.captcha && errors.captcha}
            </p>
          </div>
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  style={{ marginRight: "0.5em", borderColor: "#f14668" }}
                  name="privacyAgreement"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.privacyAgreement}
                />
                <Trans>I agree to the </Trans>
                <Link to="/privacy" style={{ textDecoration: "underline" }}>
                  <Trans>terms &amp; conditions</Trans>
                </Link>
              </label>
            </div>
            <p className="help is-danger">
              {errors.privacyAgreement &&
                touched.privacyAgreement &&
                errors.privacyAgreement}
            </p>
          </div>
          <div className="field">
            <div className="control">
              <button
                type="submit"
                disabled={isSubmitting}
                className={[
                  "button",
                  "is-link",
                  isSubmitting ? "is-loading" : null,
                ].join(" ")}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
